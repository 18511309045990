import { Alert, Button, Grid } from "@mui/material";
import { Job } from "@src/pages/Admin";
import React from "react";
import AssignTeamsONS from "./AssignTeamsONS";
import AssignUsersONS from "./AssignUsersONS";
import { useProxy } from "valtio/utils";
import { jobProxy } from "./state";
import ImpersonateUser from "./ImpersonateUser";

type ActionsProps = {};

const Actions: React.FC<ActionsProps> = () => {
  const jobProxySnap = useProxy(jobProxy);

  const downloadLogs = () => {
    const element = document.createElement("a");
    const date = new Date();
    const formattedDate = date.toISOString().replace(/:/g, '-');
    const file = new Blob([jobProxySnap.logs.join("\n")], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = `${jobProxySnap.name}_${formattedDate}.txt`;
    document.body.appendChild(element);
    element.click();
  };
  

  return (
    <div>
      <Grid container spacing={2}>
        {jobProxySnap.status !== "Idle" ? (
          <Grid item xs={12}>
            <Alert
              severity="info"
              action={
                <Button color="inherit" size="small" onClick={downloadLogs}>
                  Downloads Logs
                </Button>
              }
            >
              {jobProxySnap.name} | Current status: {jobProxySnap.status} |
              Current step: {jobProxySnap.currentStep}
            </Alert>
          </Grid>
        ) : null}
        <Grid item xs={12} md={6}>
          <AssignTeamsONS />
        </Grid>
        <Grid item xs={12} md={6}>
          <AssignUsersONS />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImpersonateUser />
        </Grid>
      </Grid>
      {/* Component content */}
    </div>
  );
};

export default Actions;

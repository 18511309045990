import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetOnsLocationByIdentificationNoQueryVariables = Types.Exact<{
  identificationNo: Types.Scalars['String'];
}>;


export type GetOnsLocationByIdentificationNoQuery = { getOnsLocationsByIdentificationNo?: { id?: number | null, identificationNo?: string | null } | null };

export type GetOnsEmployeeByIdentificationNoQueryVariables = Types.Exact<{
  identificationNo: Types.Scalars['String'];
}>;


export type GetOnsEmployeeByIdentificationNoQuery = { getOnsEmployeesByIdentificationNo?: { id?: number | null, identificationNo?: string | null } | null };

export type GetEmployeeByPkQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetEmployeeByPkQuery = { auth_user_by_pk?: { name?: string | null } | null };

export type GetAllCaretakersAndTcQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllCaretakersAndTcQuery = { auth_user: Array<{ id: any, name?: string | null, role?: Types.Auth_User_Role_Enum_Enum | null }> };

export type GetInformationForUserQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type GetInformationForUserQuery = { auth_user_by_pk?: { name?: string | null, role?: Types.Auth_User_Role_Enum_Enum | null, email?: string | null, id: any } | null };


export const GetOnsLocationByIdentificationNoDocument = gql`
    query GetOnsLocationByIdentificationNo($identificationNo: String!) {
  getOnsLocationsByIdentificationNo(identificationNo: $identificationNo) {
    id
    identificationNo
  }
}
    `;

/**
 * __useGetOnsLocationByIdentificationNoQuery__
 *
 * To run a query within a React component, call `useGetOnsLocationByIdentificationNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnsLocationByIdentificationNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnsLocationByIdentificationNoQuery({
 *   variables: {
 *      identificationNo: // value for 'identificationNo'
 *   },
 * });
 */
export function useGetOnsLocationByIdentificationNoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetOnsLocationByIdentificationNoQuery, GetOnsLocationByIdentificationNoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetOnsLocationByIdentificationNoQuery, GetOnsLocationByIdentificationNoQueryVariables>(GetOnsLocationByIdentificationNoDocument, options);
      }
export function useGetOnsLocationByIdentificationNoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOnsLocationByIdentificationNoQuery, GetOnsLocationByIdentificationNoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetOnsLocationByIdentificationNoQuery, GetOnsLocationByIdentificationNoQueryVariables>(GetOnsLocationByIdentificationNoDocument, options);
        }
export type GetOnsLocationByIdentificationNoQueryHookResult = ReturnType<typeof useGetOnsLocationByIdentificationNoQuery>;
export type GetOnsLocationByIdentificationNoLazyQueryHookResult = ReturnType<typeof useGetOnsLocationByIdentificationNoLazyQuery>;
export type GetOnsLocationByIdentificationNoQueryResult = Apollo.QueryResult<GetOnsLocationByIdentificationNoQuery, GetOnsLocationByIdentificationNoQueryVariables>;
export const GetOnsEmployeeByIdentificationNoDocument = gql`
    query GetOnsEmployeeByIdentificationNo($identificationNo: String!) {
  getOnsEmployeesByIdentificationNo(identificationNo: $identificationNo) {
    id
    identificationNo
  }
}
    `;

/**
 * __useGetOnsEmployeeByIdentificationNoQuery__
 *
 * To run a query within a React component, call `useGetOnsEmployeeByIdentificationNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnsEmployeeByIdentificationNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnsEmployeeByIdentificationNoQuery({
 *   variables: {
 *      identificationNo: // value for 'identificationNo'
 *   },
 * });
 */
export function useGetOnsEmployeeByIdentificationNoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetOnsEmployeeByIdentificationNoQuery, GetOnsEmployeeByIdentificationNoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetOnsEmployeeByIdentificationNoQuery, GetOnsEmployeeByIdentificationNoQueryVariables>(GetOnsEmployeeByIdentificationNoDocument, options);
      }
export function useGetOnsEmployeeByIdentificationNoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOnsEmployeeByIdentificationNoQuery, GetOnsEmployeeByIdentificationNoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetOnsEmployeeByIdentificationNoQuery, GetOnsEmployeeByIdentificationNoQueryVariables>(GetOnsEmployeeByIdentificationNoDocument, options);
        }
export type GetOnsEmployeeByIdentificationNoQueryHookResult = ReturnType<typeof useGetOnsEmployeeByIdentificationNoQuery>;
export type GetOnsEmployeeByIdentificationNoLazyQueryHookResult = ReturnType<typeof useGetOnsEmployeeByIdentificationNoLazyQuery>;
export type GetOnsEmployeeByIdentificationNoQueryResult = Apollo.QueryResult<GetOnsEmployeeByIdentificationNoQuery, GetOnsEmployeeByIdentificationNoQueryVariables>;
export const GetEmployeeByPkDocument = gql`
    query getEmployeeByPk($id: uuid!) {
  auth_user_by_pk(id: $id) {
    name
  }
}
    `;

/**
 * __useGetEmployeeByPkQuery__
 *
 * To run a query within a React component, call `useGetEmployeeByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeByPkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeByPkQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetEmployeeByPkQuery, GetEmployeeByPkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEmployeeByPkQuery, GetEmployeeByPkQueryVariables>(GetEmployeeByPkDocument, options);
      }
export function useGetEmployeeByPkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeByPkQuery, GetEmployeeByPkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEmployeeByPkQuery, GetEmployeeByPkQueryVariables>(GetEmployeeByPkDocument, options);
        }
export type GetEmployeeByPkQueryHookResult = ReturnType<typeof useGetEmployeeByPkQuery>;
export type GetEmployeeByPkLazyQueryHookResult = ReturnType<typeof useGetEmployeeByPkLazyQuery>;
export type GetEmployeeByPkQueryResult = Apollo.QueryResult<GetEmployeeByPkQuery, GetEmployeeByPkQueryVariables>;
export const GetAllCaretakersAndTcDocument = gql`
    query getAllCaretakersAndTC {
  auth_user(where: {role: {_in: [hulpverlener, team_coordinator]}}) {
    id
    name
    role
  }
}
    `;

/**
 * __useGetAllCaretakersAndTcQuery__
 *
 * To run a query within a React component, call `useGetAllCaretakersAndTcQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCaretakersAndTcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCaretakersAndTcQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCaretakersAndTcQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCaretakersAndTcQuery, GetAllCaretakersAndTcQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllCaretakersAndTcQuery, GetAllCaretakersAndTcQueryVariables>(GetAllCaretakersAndTcDocument, options);
      }
export function useGetAllCaretakersAndTcLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCaretakersAndTcQuery, GetAllCaretakersAndTcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllCaretakersAndTcQuery, GetAllCaretakersAndTcQueryVariables>(GetAllCaretakersAndTcDocument, options);
        }
export type GetAllCaretakersAndTcQueryHookResult = ReturnType<typeof useGetAllCaretakersAndTcQuery>;
export type GetAllCaretakersAndTcLazyQueryHookResult = ReturnType<typeof useGetAllCaretakersAndTcLazyQuery>;
export type GetAllCaretakersAndTcQueryResult = Apollo.QueryResult<GetAllCaretakersAndTcQuery, GetAllCaretakersAndTcQueryVariables>;
export const GetInformationForUserDocument = gql`
    query GetInformationForUser($id: uuid!) {
  auth_user_by_pk(id: $id) {
    name
    role
    email
    id
  }
}
    `;

/**
 * __useGetInformationForUserQuery__
 *
 * To run a query within a React component, call `useGetInformationForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInformationForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInformationForUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInformationForUserQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetInformationForUserQuery, GetInformationForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetInformationForUserQuery, GetInformationForUserQueryVariables>(GetInformationForUserDocument, options);
      }
export function useGetInformationForUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInformationForUserQuery, GetInformationForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetInformationForUserQuery, GetInformationForUserQueryVariables>(GetInformationForUserDocument, options);
        }
export type GetInformationForUserQueryHookResult = ReturnType<typeof useGetInformationForUserQuery>;
export type GetInformationForUserLazyQueryHookResult = ReturnType<typeof useGetInformationForUserLazyQuery>;
export type GetInformationForUserQueryResult = Apollo.QueryResult<GetInformationForUserQuery, GetInformationForUserQueryVariables>;
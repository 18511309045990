import { Badge, Button, IconButton, Popover, Typography } from "@mui/material";
import React, { useMemo } from "react";
import NotificationPopoverContainer from "./NotificationPopoverContainer";
import { NotificationsActive } from "@mui/icons-material";
import { useGetAllMessagesCountQuery } from "@src/graphql/generated/messages.queries.generated";

type Props = {
}

const NotificationPopover: React.FC<Props> = ({ }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const { data } = useGetAllMessagesCountQuery({
        pollInterval: 10000,
    });

    const notificationCount = useMemo(() => {
        return data?.system_messages_aggregate.aggregate?.count || 0;
    }, [data]);

    return (
        <>
            <IconButton
                color="inherit"
                aria-describedby={"notificationPopover"}
                onClick={handleClick}
            >
                <Badge badgeContent={notificationCount} color="secondary">
                    <NotificationsActive />
                </Badge>
            </IconButton>
            <Popover
                id={"notificationPopover"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <NotificationPopoverContainer closePopover={handleClose} open={open}/>
            </Popover>
        </>
    );
};

export default NotificationPopover;

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Box, Button, IconButton, Popover, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";

import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import NotificationPopoverItem from "./NotificationPopoverItem";
import { useGetAllMessagesQuery } from "@src/graphql/generated/messages.queries.generated";
import { System_Messages } from "@src/generated/types";

type Props = {
    closePopover: () => void;
    open?: boolean;
}

const NotificationPopoverContainer: React.FC<Props> = ({ closePopover, open }) => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const { data, refetch } = useGetAllMessagesQuery({
        pollInterval: 10000,
    })

    const mainNotifications = useMemo(() => {
        if (!data) return [];
        return data?.system_messages;
    }, [data]);

    const mainNotificationsUnreadCount = useMemo(() => {
        return mainNotifications.filter((item) => !item.notification_read).length;
    }, [mainNotifications]);

    const handleRefetch = () => {
        refetch();
    }

    useEffect(() => {
        if(open) refetch();
    }, [open])

    return (
        <Stack>
            <Stack direction={"row"} style={{ paddingInline: 20, paddingBlock: 10, width: 600 }} justifyContent={"space-between"}>
                <Typography variant="h6">Notificaties</Typography>
                <Stack direction={"row"} spacing={2}>
                    <IconButton aria-label="reload" onClick={handleRefetch}>
                        <CachedIcon />
                    </IconButton>
                    <IconButton aria-label="close" onClick={closePopover}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label={<Badge color="primary" badgeContent={mainNotificationsUnreadCount} max={99}>
                            <Typography variant="body2" style={{ marginInline: 10 }}>Naar mij</Typography>
                        </Badge>} value="1" />
                    </TabList>
                </Box>
                <TabPanel value="1" style={{ padding: 0, maxHeight: 600, overflow: 'auto' }}>
                    {mainNotifications.length > 0 ? mainNotifications.map((item) => <NotificationPopoverItem key={item.id} refetch={handleRefetch} item={item as System_Messages} />) : <Stack style={{ paddingBlock: 20 }} direction={"row"} justifyContent={"center"} alignItems={"center"}>Geen notificaties gevonden</Stack>}
                </TabPanel>
            </TabContext>
        </Stack>
    );
};

export default NotificationPopoverContainer;

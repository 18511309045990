import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllMessagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllMessagesQuery = { system_messages: Array<{ id: any, is_deleted: boolean, message?: string | null, notification_archived: boolean, notification_main: boolean, notification_read: boolean, receiver: any, title: string }> };

export type GetAllMessagesCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllMessagesCountQuery = { system_messages_aggregate: { aggregate?: { count: number } | null } };


export const GetAllMessagesDocument = gql`
    query GetAllMessages {
  system_messages(order_by: {created_at: desc}) {
    id
    is_deleted
    message
    notification_archived
    notification_main
    notification_read
    receiver
    title
  }
}
    `;

/**
 * __useGetAllMessagesQuery__
 *
 * To run a query within a React component, call `useGetAllMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllMessagesQuery, GetAllMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllMessagesQuery, GetAllMessagesQueryVariables>(GetAllMessagesDocument, options);
      }
export function useGetAllMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllMessagesQuery, GetAllMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllMessagesQuery, GetAllMessagesQueryVariables>(GetAllMessagesDocument, options);
        }
export type GetAllMessagesQueryHookResult = ReturnType<typeof useGetAllMessagesQuery>;
export type GetAllMessagesLazyQueryHookResult = ReturnType<typeof useGetAllMessagesLazyQuery>;
export type GetAllMessagesQueryResult = Apollo.QueryResult<GetAllMessagesQuery, GetAllMessagesQueryVariables>;
export const GetAllMessagesCountDocument = gql`
    query GetAllMessagesCount {
  system_messages_aggregate(where: {notification_read: {_eq: false}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllMessagesCountQuery__
 *
 * To run a query within a React component, call `useGetAllMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMessagesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMessagesCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllMessagesCountQuery, GetAllMessagesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllMessagesCountQuery, GetAllMessagesCountQueryVariables>(GetAllMessagesCountDocument, options);
      }
export function useGetAllMessagesCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllMessagesCountQuery, GetAllMessagesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllMessagesCountQuery, GetAllMessagesCountQueryVariables>(GetAllMessagesCountDocument, options);
        }
export type GetAllMessagesCountQueryHookResult = ReturnType<typeof useGetAllMessagesCountQuery>;
export type GetAllMessagesCountLazyQueryHookResult = ReturnType<typeof useGetAllMessagesCountLazyQuery>;
export type GetAllMessagesCountQueryResult = Apollo.QueryResult<GetAllMessagesCountQuery, GetAllMessagesCountQueryVariables>;
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { alpha, darken } from "@mui/material/styles";
import React from "react";

import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { System_Messages } from "@src/generated/types";
import { useDeleteMessageMutation, useMarkMessageAsReadMutation } from "@src/graphql/generated/messages.mutations.generated";

type Props = {
    item: System_Messages;
    refetch: () => void;
}

const NotificationPopoverItem: React.FC<Props> = ({
    item,
    refetch,
}) => {
    const theme = useTheme();

    const [deleteMessage] = useDeleteMessageMutation({
        onCompleted: () => {
            refetch();
        }
    });
    const [markAsRead] = useMarkMessageAsReadMutation({
        onCompleted: () => {
            refetch();
        }
    });

    return (
        <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
                paddingTop: 1,
                paddingBottom: 1.5,
                paddingInline: 2,
                backgroundColor: !item.notification_read ? alpha(theme.palette.primary.main, 0.2) : "inherit",
                '&:hover': {
                    backgroundColor: darken(!item.notification_read ? alpha(theme.palette.primary.main, 0.3) : theme.palette.action.hover, 0.1),
                },
            }}
        >
            <Stack sx={{flexGrow: 1, paddingRight: 2 }}>
                <Typography variant="body1" flexWrap={"wrap"}>{item.title.replaceAll("</br>", "\n")}</Typography>
                {item.message && <Typography variant="body2" color="textSecondary" flexWrap={"wrap"}>{item.message.replaceAll("</br>", "\n")}</Typography>}
            </Stack>
            <Stack direction={"row"} spacing={0}>

                <IconButton disabled={item.notification_read} onClick={() => markAsRead({
                    variables: {
                        id: item.id
                    }
                })}>
                    <CheckIcon />
                </IconButton>
                <IconButton onClick={() => deleteMessage({
                    variables: {
                        id: item.id
                    }
                })}>
                    <DeleteIcon />
                </IconButton>
                {/* <IconButton onClick={() => GoToLink({
                    variables: {
                        id: item.id
                    }
                })}>
                    <DeleteIcon />
                </IconButton> */}
            </Stack>
        </Stack>
    );
};

export default NotificationPopoverItem;
